export const CONNECTIVITY_FILE_PATH = 'connectivity.json'
export const NOTIFICATIONS_FILE_PATH = 'notifications.json'
export const SETTINGS_FILE_PATH = 'settings.json'
export const ALBUMS_FILE_PATH = 'albums.json'
export const IMAGES_FILE_PATH = 'images.json'
export const DEFAULT_ALBUM_NAME = 'Album'
export const DEFAULT_ALBUM_DIRECTORY_NAME = 'Directory'
export const DEFAULT_UPLOAD_FILE_CHUNK_SIZE = 5 * 1000 * 1000
export const TOAST_SHOW_DURATION = 3000
export const DISPATCHER_THROTTLE_INTERVAL = 100
export const IMAGE_PREVIEW_SIZE = 512
export const SAVE_DATABASE_DEBOUNCE_DURATION = 60 * 1000

export const DEFAULT_CONCURRENT_DOWNLOADS_LIMIT = 10
export const DEFAULT_CONCURRENT_ENCRYPTION_LIMIT = 1
export const DEFAULT_CONCURRENT_FILE_READS_LIMIT = 1
export const DEFAULT_CONCURRENT_RAF_LIMIT = 1
export const DEFAULT_CONCURRENT_RECORD_OPERATIONS_LIMIT = 3
export const DEFAULT_CONCURRENT_UPLOADS_LIMIT = 3
